import * as React from 'react';
import { fillRef } from '../ref';
function useCombinedRefs(...refs) {
    const targetRef = React.useRef();
    React.useEffect(() => {
        refs.forEach(ref => {
            if (!ref)
                return;
            fillRef(ref, targetRef.current);
        });
    }, [refs]);
    return targetRef;
}
export default useCombinedRefs;
