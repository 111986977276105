var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import { ConfigContext } from '../config-provider';
import Item from './Item';
export const LastIndexContext = React.createContext(0);
const Space = props => {
    const { getPrefixCls, space, direction: directionConfig } = React.useContext(ConfigContext);
    const { size = (space === null || space === void 0 ? void 0 : space.size) || 'small', align, className, children, direction = 'horizontal', prefixCls: customizePrefixCls, split } = props, otherProps = __rest(props, ["size", "align", "className", "children", "direction", "prefixCls", "split"]);
    const childNodes = toArray(children, { keepEmpty: true });
    if (childNodes.length === 0) {
        return null;
    }
    const mergedAlign = align === undefined && direction === 'horizontal' ? 'center' : align;
    const prefixCls = getPrefixCls('space', customizePrefixCls);
    const cn = classNames(prefixCls, `${prefixCls}-${direction}`, {
        [`${prefixCls}-rtl`]: directionConfig === 'rtl',
        [`${prefixCls}-align-${mergedAlign}`]: mergedAlign,
    }, className);
    const itemClassName = `${prefixCls}-item`;
    const marginDirection = directionConfig === 'rtl' ? 'marginLeft' : 'marginRight';
    // Calculate latest one
    let latestIndex = 0;
    const nodes = childNodes.map((child, i) => {
        if (child !== null && child !== undefined) {
            latestIndex = i;
        }
        /* eslint-disable react/no-array-index-key */
        return (<Item className={itemClassName} key={`${itemClassName}-${i}`} direction={direction} size={size} index={i} marginDirection={marginDirection} split={split}>
        {child}
      </Item>);
        /* eslint-enable */
    });
    return (<div className={cn} {...otherProps}>
      <LastIndexContext.Provider value={latestIndex}>{nodes}</LastIndexContext.Provider>
    </div>);
};
export default Space;
