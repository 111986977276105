var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';
import FieldForm, { List } from 'rc-field-form';
import { ConfigContext } from '../config-provider';
import { FormContext } from './context';
import useForm, { FormInstance } from './hooks/useForm';
import SizeContext, { SizeContextProvider } from '../config-provider/SizeContext';
const InternalForm = (props, ref) => {
    const contextSize = React.useContext(SizeContext);
    const { getPrefixCls, direction, form: contextForm } = React.useContext(ConfigContext);
    const { name } = props;
    const { prefixCls: customizePrefixCls, className = '', size = contextSize, form, colon, labelAlign, labelCol, wrapperCol, hideRequiredMark, layout = 'horizontal', scrollToFirstError, requiredMark, onFinishFailed } = props, restFormProps = __rest(props, ["prefixCls", "className", "size", "form", "colon", "labelAlign", "labelCol", "wrapperCol", "hideRequiredMark", "layout", "scrollToFirstError", "requiredMark", "onFinishFailed"]);
    const mergedRequiredMark = useMemo(() => {
        if (requiredMark !== undefined) {
            return requiredMark;
        }
        if (contextForm && contextForm.requiredMark !== undefined) {
            return contextForm.requiredMark;
        }
        if (hideRequiredMark) {
            return false;
        }
        return true;
    }, [hideRequiredMark, requiredMark, contextForm]);
    const prefixCls = getPrefixCls('form', customizePrefixCls);
    const formClassName = classNames(prefixCls, {
        [`${prefixCls}-${layout}`]: true,
        [`${prefixCls}-hide-required-mark`]: mergedRequiredMark === false,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-${size}`]: size,
    }, className);
    const [wrapForm] = useForm(form);
    const { __INTERNAL__ } = wrapForm;
    __INTERNAL__.name = name;
    const formContextValue = useMemo(() => ({
        name,
        labelAlign,
        labelCol,
        wrapperCol,
        vertical: layout === 'vertical',
        colon,
        requiredMark: mergedRequiredMark,
        itemRef: __INTERNAL__.itemRef,
    }), [name, labelAlign, labelCol, wrapperCol, layout, colon, mergedRequiredMark]);
    React.useImperativeHandle(ref, () => wrapForm);
    const onInternalFinishFailed = (errorInfo) => {
        if (onFinishFailed) {
            onFinishFailed(errorInfo);
        }
        if (scrollToFirstError && errorInfo.errorFields.length) {
            wrapForm.scrollToField(errorInfo.errorFields[0].name);
        }
    };
    return (<SizeContextProvider size={size}>
      <FormContext.Provider value={formContextValue}>
        <FieldForm id={name} {...restFormProps} onFinishFailed={onInternalFinishFailed} form={wrapForm} className={formClassName}/>
      </FormContext.Provider>
    </SizeContextProvider>);
};
const Form = React.forwardRef(InternalForm);
export { useForm, List, FormInstance };
export default Form;
