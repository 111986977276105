var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcSlider, { Range as RcRange, Handle as RcHandle } from 'rc-slider';
import classNames from 'classnames';
import SliderTooltip from './SliderTooltip';
import { ConfigContext } from '../config-provider';
const Slider = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction, getPopupContainer } = React.useContext(ConfigContext);
    const [visibles, setVisibles] = React.useState({});
    const toggleTooltipVisible = (index, visible) => {
        setVisibles((prev) => {
            return Object.assign(Object.assign({}, prev), { [index]: visible });
        });
    };
    const getTooltipPlacement = (tooltipPlacement, vertical) => {
        if (tooltipPlacement) {
            return tooltipPlacement;
        }
        if (!vertical) {
            return 'top';
        }
        return direction === 'rtl' ? 'left' : 'right';
    };
    const handleWithTooltip = (_a) => {
        var { tooltipPrefixCls, prefixCls } = _a, _b = _a.info, { value, dragging, index } = _b, restProps = __rest(_b, ["value", "dragging", "index"]);
        const { tipFormatter, tooltipVisible, tooltipPlacement, getTooltipPopupContainer, vertical, } = props;
        const isTipFormatter = tipFormatter ? visibles[index] || dragging : false;
        const visible = tooltipVisible || (tooltipVisible === undefined && isTipFormatter);
        return (<SliderTooltip prefixCls={tooltipPrefixCls} title={tipFormatter ? tipFormatter(value) : ''} visible={visible} placement={getTooltipPlacement(tooltipPlacement, vertical)} transitionName="zoom-down" key={index} overlayClassName={`${prefixCls}-tooltip`} getPopupContainer={getTooltipPopupContainer || getPopupContainer || (() => document.body)}>
          <RcHandle {...restProps} value={value} onMouseEnter={() => toggleTooltipVisible(index, true)} onMouseLeave={() => toggleTooltipVisible(index, false)}/>
        </SliderTooltip>);
    };
    const { prefixCls: customizePrefixCls, tooltipPrefixCls: customizeTooltipPrefixCls, range, className } = props, restProps = __rest(props, ["prefixCls", "tooltipPrefixCls", "range", "className"]);
    const prefixCls = getPrefixCls('slider', customizePrefixCls);
    const tooltipPrefixCls = getPrefixCls('tooltip', customizeTooltipPrefixCls);
    const cls = classNames(className, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    });
    // make reverse default on rtl direction
    if (direction === 'rtl' && !restProps.vertical) {
        restProps.reverse = !restProps.reverse;
    }
    if (range) {
        return (<RcRange {...restProps} step={restProps.step} className={cls} ref={ref} handle={(info) => handleWithTooltip({
            tooltipPrefixCls,
            prefixCls,
            info,
        })} prefixCls={prefixCls}/>);
    }
    return (<RcSlider {...restProps} step={restProps.step} className={cls} ref={ref} handle={(info) => handleWithTooltip({
        tooltipPrefixCls,
        prefixCls,
        info,
    })} prefixCls={prefixCls}/>);
});
Slider.displayName = 'Slider';
Slider.defaultProps = {
    tipFormatter(value) {
        return typeof value === 'number' ? value.toString() : '';
    },
};
export default Slider;
