import * as React from 'react';
import { LastIndexContext } from '.';
const spaceSize = {
    small: 8,
    middle: 16,
    large: 24,
};
export default function Item({ className, direction, index, size, marginDirection, children, split, }) {
    var _a;
    const latestIndex = React.useContext(LastIndexContext);
    if (children === null || children === undefined) {
        return null;
    }
    const style = index >= latestIndex
        ? {}
        : {
            [direction === 'vertical' ? 'marginBottom' : marginDirection]: ((_a = (typeof size === 'string' ? spaceSize[size] : size)) !== null && _a !== void 0 ? _a : 0) / (split ? 2 : 1),
        };
    return (<>
      <div className={className} style={style}>
        {children}
      </div>
      {index < latestIndex && split && (<span className={`${className}-split`} style={style}>
          {split}
        </span>)}
    </>);
}
